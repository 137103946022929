/**
 * Front application bootstrap:
 *
 * - global vuejs components/directives/mixins/filters
 * - raven
 */

import 'core-js/features/array/reduce';
import 'core-js/features/object/assign';
import 'core-js/features/object/values';
import 'core-js/features/promise';
import 'core-js/features/promise/finally';

import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';

import * as components from './components';
import * as directives from './directives';
import * as filters from './filters';
import * as mixins from './mixins';

// global definition of components, directives and filters
Object.values(components).forEach((component) => {
  Vue.component(component.name, component);
});

Object.values(directives).forEach((directiveProvider) => {
  directiveProvider(Vue);
});

Object.values(filters).forEach((filterProvider) => {
  filterProvider(Vue);
});

// provide all functionality via browserify-shim (see package.json)
global.AppFront = {
  ...components,
  ...directives,
  ...mixins,
  ...filters,
};

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://48a8870ba8994aa09d630959f4ba0062@sentry.io/128101',
    environment: global.sentryEnvironment || 'unconfigured-env',
    integrations: [new VueIntegration({ Vue, attachProps: true })],
    tracesSampleRate: 0.1,
  });

  Sentry.configureScope(function (scope) {
    scope.setTag('application', 'sites');
  });
}
